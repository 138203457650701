@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FE !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-canvas: #ffffff;
  --color-canvas-inverted: #333333;

  --color-brand-primary: #5bb9eb;
  --color-brand-secondary: #c5dcff;
  --color-brand-tertiary: #f64e00;

  --color-text-primary: #222222;
  --color-text-secondary: #ffffff;
  --color-text-tertiary: #5bb9eb;
  --color-text-inverted: #ffffff;

  --color-ui-primary: #5bb9eb;
  --color-ui-primary-500: rgba(91, 185, 235, 0.5);
  --color-ui-secondary: #b8b8b8;
  --color-ui-ok: #24BA7C;
  --color-ui-warn: yellow;
  --color-ui-danger: #DB433D;

  /* Active State (hover, focus) */
  --hilite-brand-primary: #00b19f;
  --hilite-brand-secondary: #fff;
  --hilite-text-primary: #fff;
  --hilite-text-secondary: #fff;

  /* Inactive State */
  --dim-brand-primary: #fff;
  --dim-brand-secondary: #fff;
  --dim-text-primary: #fff;
  --dim-text-secondary: #fff;
}