.vehicle_card {
  cursor: pointer;
  color: #333;
  /* transition: all 0.3s ease-in; */
}
.vehicle_card:hover {
  /* transform: scale(1.04); */
}

.vehicle_card {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-ui-secondary);
}

.vehicle_image {
  width: 125px;
  min-width: 125px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  height: 100%;
  max-height: 126px;
}

.pictures_number {
  position: absolute;
  z-index: 9;
  bottom: 10px;
  color: #fff;
  padding: 1px 6px;
  border-radius: 3px;
  left: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 0.75rem;
  background: rgb(0 0 0 / 35%);
}

.vehicle_data {
  overflow: hidden;
}

.smoke_color {
  color: var(--color-ui-secondary);
}

.no_image {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: var(--color-ui-primary);
}

@media screen and (max-width: 480px) {
  .vehicle_card .vehicle_content {
    flex-direction: column;
  }
  .vehicle_data {
    order: 2;
    align-self: flex-start;
  }
  .vehicle_image {
    order: 1;
  }
}
