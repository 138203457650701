/* invoices */
.ribbon_invoice {
  position: absolute;
  right: -20px;
  top: 0;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 0.8rem;
}

.gray_ribbon {
  color: rgb(73, 73, 73);
  border: 1px solid rgb(73, 73, 73);
}

.green_ribbon {
  color: rgb(59, 197, 31);
  border: 1px solid rgb(59, 197, 31);
}

.yellow_ribbon {
  color: rgb(223, 209, 17);
  border: 1px solid rgb(223, 209, 17);
}

.red_button {
  background-color: #FEDCDE;
  border-color: #FEDCDE;
  color: #B32F3F;
}

.red_button:hover {
  background-color: #ffbec2;
  border-color: #ffbec2;
  color: #a32a38;
}

.credits_data p {
  font-weight: 200;
}