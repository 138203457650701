.dealership_modal_layer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 17;
}

.dealership {
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 10px;
  transition: all 0.2s;
}

.dealership:hover {
  background-color: #eee;
}
