.dots_container {
  align-self: start;
  /* background: #ececec; */
  /* padding: 5px 12px; */
  /* border-radius: 3px;
  font-size: 0.8rem;
  margin-left: 6px; */

  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 9px;
  cursor: pointer;
  transition: background 0.3s;
}
.dots_container:hover {
  background: #ececec;
}

.three_dots {
  position: relative;
  width: 3px;
  height: 3px;
  background-color: rgb(137, 137, 137);
  border-radius: 50%;
  transition: all 0.2s;
}

.three_dots:before,
.three_dots:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  background-color: inherit;
  border-radius: inherit;
}

.three_dots:before {
  top: 6px;
}

.three_dots:after {
  top: 12px;
}