.flex_between span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.code span {
  background: var(--color-brand-primary);
  color: #fff;
  padding-left: 5px;
  font-size: 0.8rem;
}
.round_logo {
  border-radius: 50%;
  overflow: hidden;
  height: 32px;
}
