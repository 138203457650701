.auth_container {
  background: transparent;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth_container::before {
  z-index: -1;
  content: '';
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-position: top;
  /* clip-path: polygon(60% 0, 100% 0%, 100% 100%, 21% 100%); */
  background: linear-gradient(
    119deg,
    transparent 40%,
    var(--color-brand-primary) 40.2%
  );
}


@media screen and (max-width: 525px) {
  .auth_container {
    background: var(--color-canvas);
  }
}