.button_container {
  padding: 14px 8px;
  /* position: absolute; */
  /* display: none; */
  /* top: 0; */
  /* background: red; */
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  /* border: 1px solid #ebebeb; */
  border-bottom: none;
  /* min-height: 100px; */
  background: linear-gradient(179deg, white, #F8F9FE);
}

.additional_request {
  background: #51bb7b;
  color: #fff;
  padding: 5px 8px;
  margin: 5px 0;
  border-radius: 5px;
}
