.login_card {
  background-color: var(--color-canvas);
  max-width: 500px;
  padding: 2rem;
  margin: 16px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 6px;
}

.transition {
  animation: slide-down 300ms ease-out forwards;
}

@media screen and (max-width: 525px) {
  .login_card {
    box-shadow: none;
    padding: 1rem;
    width: 360px;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: scale(0.75);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}