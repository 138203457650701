.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.background_primary {
  background-color: var(--color-ui-primary);
}

.badge {
  position: relative;
  left: -200px;
}

.collapsed_desktop {
  left: -80px;
}

.collapsed_mobile {
  left: -10px;
}
.custom_badge {
  margin: 0 5px;
}

.search_button {
  display: none;
  background-color: var(--color-ui-primary);
  color: #ffffff;
  cursor: pointer;
}

.user_avatar_container {
  border-radius: 50px;
  padding: 5px 8px;
  line-height: initial;
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_avatar_container:hover {
  background: #f1f1f1;
}

@media screen and (max-width: 575px) {
  .user_avatar_container:hover {
    background-color: transparent;
  }
  .user_avatar_container .avatar_name {
    display: none;
  }
  .search_button {
    display: block;
  }
  .search_input {
    display: none;
  }
}
