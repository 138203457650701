@import 'antd/dist/antd.variable.min.css';

.delete {
  color: var(--color-ui-danger);
  transition: all 0.3s;
}

.gray-clr {
  color: #adadad;
}

.no_data {
  text-align: center;
  margin-top: 30px;
  color: #adadad;
  font-size: 0.9rem;
}

.picture_background {
  position: relative;
  width: 100%;
  height: 239px;
  background: url('./assets/img/cover-photo.png'), var(--color-ui-primary);
  color: var(--color-ui-primary);
  background-size: cover;
  background-position: initial;
}

.card_wrapper {
  background: #f8f9fe;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  padding: 25px;
  align-self: baseline;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
}

.camera_button_wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #f8f9fe;
  padding: 0;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  z-index: 5;
  overflow: hidden;
}

.camera_button {
  color: #44aee5;
}

.camera_button:hover {
  color: #44aee5 !important;
}

.avatarcic {
  position: absolute;
  bottom: -35px;
  left: 15%;
  z-index: 9;
  left: 165px;
  padding: 4px;
  background: #fff;
  border-radius: 50%;
  min-height: 70px;
}

.user_name {
  font-weight: bold;
  font-size: 16px;
  color: #3c495a;
}

@keyframes show {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(221, 24, 24, 0.5);
}

.vin {
  font-size: 0.7rem;
  font-weight: 400;
  padding: 0px 5px;
  border: 2px solid var(--color-ui-primary);
  margin-right: 6px;
}

.no_wrap_text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text_header_divider {
  border-right: 1px solid #ccc;
  margin-right: 10px;
  width: 10px;
  height: 30px;
}

.not_found_text {
  text-align: center;
  margin-top: 3rem;
}

.separator {
  border-right: 1px solid #ccc;
  margin-right: 10px;
  width: 10px;
  height: 30px;
}

.page_header_card {
  background: #fff;
  padding: 10px;
  position: sticky;
  top: 52px;
  z-index: 9;
  border-top: 1px solid #f4f4f4;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
}

.vehicle_image {
  width: 100% !important;
  vertical-align: middle;
  /* aspect-ratio: 16 / 9; */
}
.request_image {
  width: 110px;
  /* height: 45px; */
}
.request_image {
  height: 70px;
}
.request_blur_image {
  width: 80px;
  /* aspect-ratio: 16 / 9; */
}
.logo_image {
  width: 32px;
  height: auto;
}
.vehicle_item_image {
  width: 160px;
  height: 90px;
}

.filter_container {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ededed;
  border-radius: 11px;
  padding-top: 5px;
}

.filter_container label {
  padding: 0 11px;
  font-size: 0.8rem;
  color: var(--color-ui-primary);
}

.filter_buttons {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  padding: 0 30px;
}

.select_box {
  width: 130px;
}

.avatar {
  width: 70px;
  height: 70px;
  line-height: 60px;
  font-size: 2rem !important;
  background-color: var(--color-brand-primary);
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.bordered_image {
  border-radius: 5px;
  overflow: hidden;
}

.feature-date {
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.feature-date:hover {
  transform: scale(1.1);
}

.big_text {
  text-transform: uppercase;
}

@media screen and (max-width: 1200px) {
  .card_wrapper {
    padding: 5px 0;
  }
}

@media screen and (max-width: 767px) {
  .select_box {
    /* width: 105px; */
    font-size: 0.9rem;
  }

  .card_wrapper {
    border: none;
    padding: 25px 5px;
    border-radius: none;
    box-shadow: none;
  }

  .picture_background {
    height: 200px;
  }

  /* .a {
    margin-top: 176px;
  } */

  .avatarcic {
    left: 50%;
    transform: translateX(-50%);
  }
}

/*  */

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 52px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: var(--hilite-brand-primary);
}

.logo {
  text-align: center;
  height: 36px;
  margin: 8px;
  background: rgba(255, 255, 255, 1);
}

.site-layout .site-layout-background {
  background: #fff;
  padding: 0 25px;
}

.ant-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.04);
}

.ant-badge-count {
  transform: translate(35%, -35%) !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  color: var(--color-ui-primary);
}

.ant-picker {
  width: 100%;
}

/* .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 20px 20px 0;
  border-left: none;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
} */

.ert {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}
.ant-avatar {
  line-height: initial;
}

.custom_input {
  border: none !important;
  border-bottom: 1px solid #efefef !important;
  border-radius: initial !important;
}

.custom_input:focus {
  box-shadow: none !important;
}

.custom_input:hover {
  border-color: var(--color-ui-primary-500) !important;
}

/* Custom CSS for the toast container */
.custom-toast-container {
  width: 300px;
}

.custom-toast {
  display: flex;
  gap: 1rem;
}

.popup-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 999;
  max-width: 490px;
  width: 490px;
  background: #fff;
  box-shadow: 0px 0px 10px 1px #bdbdbd;
  border-radius: 3px;
  padding: 1rem;
  padding-top: 6px;
}

.popup-content {
  display: flex;
  gap: 1.2rem;
}

.popup-text {
  width: 70%;
  margin-top: 8px;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.6rem;
  margin-top: .6rem;
}

.popup-buttons > button {
  text-transform: uppercase;
}

.ant-popover-inner {
  max-width: 400px;
}

@media screen and (max-width: 575px) {
  .page_info {
    align-items: baseline;
  }

  .popup-container {
    width: 100%;
    max-width: 100%;
  }

  /* .select_box {
      width: 100%;
    } */
}
