.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primary_clr {
  color: var(--color-ui-primary);
}