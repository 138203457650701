.page_header {
  margin: 24px 30px 10px 30px;
}

.search_button {
  display: none;
  visibility: hidden;
  cursor: pointer;
  border-radius: 2px;
  width: 30px;
  background-color: transparent;
  color: var(--color-ui-primary);
  font-size: 22px;
  justify-content: center;
  align-items: center;
}

.rounded_button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 20;
  border-radius: 50px;
  overflow: hidden;
}

.rounded_button button {
  width: 50px;
  padding: 28px;
}

@media screen and (max-width: 768px) {
  /* .page_header {
    margin: 0;
  } */
}

@media screen and (max-width: 575px) {
  .page_header div {
    flex-wrap: wrap;
  }

  .search_button {
    display: flex;
    visibility: visible;
  }
  .search_input {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 425px) {
  .page_header {
    margin: 0;
  }
}
