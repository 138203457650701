.color {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.swatch {
  width: 100%;
  height: 30px;
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}

.popover {
  position: absolute;
  z-index: 2;
}
