.test::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.test::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8);*/ 
  -webkit-border-radius: 10px;
  background-color:#fff;
  border-radius: 10px;
}

/* Handle */
.test::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background:#666; 
  -webkit-box-shadow: inset 0 0 17px var(--color-ui-primary) 
}