.invoice_header {
  position: relative;
  word-break: break-all;
  overflow: hidden;
}

.thin_font {
  font-weight: 200;
}

.flex_between span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}