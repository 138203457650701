.badge {
  font-size: 0.7rem;
  padding: 1px 3px;
  border-radius: 2px;
}
.default_badge {
  background: var(--color-ui-primary);
  color: #fff;
}
.admin_badge {
  border: 1px solid var(--color-ui-secondary);
  color: var(--color-ui-secondary);
}