p {
  margin: 0;
}

.user_email {
  color: var(--color-ui-secondary);
}
.flex_between span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.avatar {
  width: 70px;
  height: 70px;
  line-height: 60px;
  font-size: 2rem !important;
  background-color: var(--color-brand-primary);
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.divider {
  width: 25%;
  border-bottom: 1px solid #d5d5d5;
  margin: auto;
  margin-bottom: -2px;
}
.view_profile {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.view_profile a {
  flex: 1;
  line-height: 60px;
}
.view_profile:hover {
  background-color: var(--color-brand-primary);
}
.view_profile:hover a {
  color: #fff;
}
