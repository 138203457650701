.spinner_container {
  position: absolute;
  z-index: 1002;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: white; */
}
.splash_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 425px) {
  h2 {
    font-size: 1.2rem;
  }
}