p {
  margin: 0;
}
.smoked_text {
  color: #b7b7b7;
}
.icon {
  font-size: 0.95rem;
  padding: 5px;
  margin-top: 3px;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
  transition: all 0.2s;
}
.icon.green:hover {
  background: var(--color-ui-primary);
  color: #fff;
  transform: scale(1.15);
}
.icon.red:hover {
  background: var(--color-ui-danger);
  color: #fff;
  transform: scale(1.15);
}
.green {
  color: var(--color-ui-primary);
  /* border: 1px solid var(--color-ui-primary); */
}
.red {
  color: var(--color-ui-danger);
  /* border: 1px solid var(--color-ui-danger); */
}
.status {
  min-width: 85px;
  text-align: end;
  align-self: stretch;
  background: rgb(244, 244, 244);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}
.badge {
  padding: 1px 4px;
  background: var(--color-ui-primary-500);
  /* color: #fff; */
  border-radius: 3px;
  font-size: 0.7rem;
}
.image {
  border-radius: 3px;
  overflow: hidden;
  text-align: end;
}

.more_info {
  cursor: pointer;
  color: var(--color-ui-primary);
  display: none;
}

.no_image {
  width: 80px;
  position: relative;
  display: flex;
  min-height: 45px;
  background-color: #efefef;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-size: 1.8rem;
  color: var(--color-ui-primary);
}

.vehicle_created {
  position: absolute;
  font-size: 0.8rem;
  color: var(--color-ui-primary);
  background: #f4f4f4;
  padding: 0 1rem;
}

.request_data {
  flex-grow: 1;
  padding: 15px 0;
}

.request_checkbox {
  padding: 6px 0.9rem 0 1.2rem;
  align-self: baseline;
  min-width: 50px;
  position: relative;
}

.request_checkbox label {
  position: absolute;
  z-index: 999;
}

@media screen and (max-width: 765px) {
  .user_data {
    display: none;
  }
  .more_info {
    display: block;
  }
  .request_container {
    flex-direction: column;
  }
  .request_data {
    width: 100%;
    padding: 15px 10px;
  }
  .status {
    margin: 0;
    padding: 8px 0;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .request_data {
    flex-direction: column-reverse;
    padding: 30px 10px;
  }
}
