.form_wrapper {
  width: 320px;
  padding: 1rem;
  margin-top: 1rem;
}

.txt_icon {
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 16px;
}

.success_password {
  padding: 15px;
  background: #cefde0;
  border-radius: 6px;
}

@media screen and (max-width: 320px) {
  .form_wrapper {
    width: 100%;
  }
}
